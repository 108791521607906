import en from './locales/en';

export default {

    locale: 'en',

    pool: {
        en,
    },

    get(id : string) : string {
        const key = this.locale as string;
        const pool = this.pool as any;
        const data = pool[key] as any;
        const keys = Object.keys(data);

        if(keys.includes(id))
        {
            return data[id];
        }

        return id;
    }

}