import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Locale from "./../locale";
import DashboardView from "../views/user/DashboardView.vue";
import ElectionCodeView from "../views/election/ElectionCodeView.vue";
import ElectionRegisterView from "../views/election/ElectionRegisterView.vue";
import ElectionStartView from "../views/election/ElectionStartView.vue";
import ElectionVoteBODView from "../views/election/ElectionVoteBODView.vue";
import ElectionVoteElecomOfficerView from "../views/election/ElectionVoteElecomOfficerView.vue";
import ElectionVoteElecomEnlistedPersonelView from "../views/election/ElectionVoteElecomEnlistedPersonelView.vue";
import ElectionVoteElecomCivilianPersonelView from "../views/election/ElectionVoteElecomCivilianPersonelView.vue";
import ElectionVoteAdcomView from "../views/election/ElectionVoteAdcomView.vue";
import ElectionVotePreviewView from "../views/election/ElectionVotePreviewView.vue";
import ElectionVoteSuccessView from "../views/election/ElectionVoteSuccessView.vue";
import ElectionRatingView from "../views/election/ElectionRatingView.vue";
import ErrorView from "../views/ErrorView.vue";
import ForgotPasswordView from "../views/forgot-password/ForgotPasswordView.vue";
import ForgotPasswordOTPValidateView from "../views/forgot-password/ForgotPasswordOTPValidateView.vue";
import HelpView from "../views/HelpView.vue";
import LoginView from "../views/LoginView.vue";
import OurCompanyView from "../views/static/OurCompanyView.vue";
import HelpCenterView from "../views/static/HelpCenterView.vue";
import PrivacyPolicyView from "../views/static/PrivacyPolicyView.vue";
import RegisterView from "../views/registration/RegisterView.vue";
import RegisterValidateView from "../views/registration/RegisterValidateView.vue";
import RegistrationSuccess from "../views/registration/RegistrationSuccess.vue";
import SettingsView from "../views/user/SettingsView.vue";
import TermsView from "../views/static/TermsView.vue";
import UpdatePasswordView from "../views/forgot-password/UpdatePasswordView.vue";
import UserProfileView from "../views/user/UserProfileView.vue";
import WelcomeView from "../views/WelcomeView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "welcome",
    meta: {
      title: Locale.get("welcome"),
      auth: false,
      passive: false,
    },
    component: WelcomeView,
  },
  {
    path: "/login",
    name: "login",
    meta: {
      title: Locale.get("welcome.ka.may.ari"),
      auth: false,
      passive: false,
    },
    component: LoginView,
  },
  {
    path: "/help",
    name: "help",
    meta: {
      title: "Help",
      auth: false,
      passive: false,
    },
    component: HelpView,
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    meta: {
      title: Locale.get("password.recovery"),
      auth: false,
      passive: false,
    },
    component: ForgotPasswordView,
  },
  {
    path: "/forgot-password/validate",
    name: "forgot-password-validate",
    meta: {
      title: 'ONE TIME PIN',
      auth: false,
      passive: false,
    },
    component: ForgotPasswordOTPValidateView,
  },
  {
    path: "/forgot-password/update",
    name: "update-password",
    meta: {
      title: 'Update Password',
      auth: false,
      passive: false,
    },
    component: UpdatePasswordView,
  },
  {
    path: "/register",
    name: "register",
    meta: {
      title: Locale.get('create.an.account'),
      auth: false,
      passive: false,
    },
    component: RegisterView,
  },
  {
    path: "/register/validate",
    name: "register-validate",
    meta: {
      title: 'One Time Pin',
      auth: false,
      passive: false,
    },
    component: RegisterValidateView,
  },
  {
    path: "/register/success",
    name: "RegisterSuccess",
    meta: {
      title: Locale.get('success'),
      auth: false,
      passive: false,
    },
    component: RegistrationSuccess,
  },
  {
    path: "/end-user-license-agreement",
    name: "end-user-license-agreement",
    meta: {
      title: 'End User License Agreement',
      auth: false,
      passive: true,
    },
    component: TermsView,
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    meta: {
      title: Locale.get("privacy.policy"),
      auth: false,
      passive: true,
    },
    component: PrivacyPolicyView,
  },
  {
    path: "/our-company",
    name: "our-company",
    meta: {
      title: "Our Company",
      auth: false,
      passive: true,
    },
    component: OurCompanyView,
  },
  {
    path: "/help-center",
    name: "help-center",
    meta: {
      title: "Help Center",
      auth: false,
      passive: false,
    },
    component: HelpCenterView,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    meta: {
      title: Locale.get("dashboard"),
      auth: true,
      passive: false,
    },
    component: DashboardView,
  },
  {
    path: "/election/getting-started",
    name: "election",
    meta: {
      title: 'Election Registration',
      auth: true,
      passive: false,
    },
    component: ElectionRegisterView,
  },
  {
    path: "/election/code",
    name: "election-code",
    meta: {
      title: Locale.get('election.Code'),
      auth: true,
      passive: false,
    },
    component: ElectionCodeView,
  },
  {
    path: "/election/start",
    name: "election-start",
    meta: {
      title: 'Voter OTP',
      auth: true,
      passive: false,
    },
    component: ElectionStartView,
  },
  {
    path: "/election/vote/bod",
    name: "election-bod",
    meta: {
      title: Locale.get('board.of.directors'),
      auth: true,
      passive: false,
    },
    component: ElectionVoteBODView,
  },
  {
    path: "/election/vote/elecom/officers",
    name: "election-elecom-officer",
    meta: {
      title: Locale.get('election.committee'),
      auth: true,
      passive: false,
    },
    component: ElectionVoteElecomOfficerView,
  },
  {
    path: "/election/vote/elecom/enlisted-personnel",
    name: "election-elecom-enlisted-personel",
    meta: {
      title: Locale.get('election.committee'),
      auth: true,
      passive: false,
    },
    component: ElectionVoteElecomEnlistedPersonelView,
  },
  {
    path: "/election/vote/elecom/civilian",
    name: "election-elecom-civilian",
    meta: {
      title: Locale.get('election.committee'),
      auth: true,
      passive: false,
    },
    component: ElectionVoteElecomCivilianPersonelView,
  },
  {
    path: "/election/vote/adcom",
    name: "election-adcom",
    meta: {
      title: Locale.get('audit.and.supervisory.committee'),
      auth: true,
      passive: false,
    },
    component: ElectionVoteAdcomView,
  },
  {
    path: "/election/vote/preview",
    name: "election-preview",
    meta: {
      title: Locale.get('vote.summary'),
      auth: true,
      passive: false,
    },
    component: ElectionVotePreviewView,
  },
  {
    path: "/election/vote/success",
    name: "election-vote-success",
    meta: {
      title: 'Vote Receipt',
      auth: true,
      passive: false,
    },
    component: ElectionVoteSuccessView,
  },
  {
    path: "/election/vote/remarks",
    name: "election-remarks",
    meta: {
      title: 'How is your experience?',
      auth: false,
      passive: true,
    },
    component: ElectionRatingView,
  },
  {
    path: "/user/settings",
    name: "settings",
    meta: {
      title: Locale.get('user.settings'),
      auth: true,
      passive: false,
    },
    component: SettingsView,
  },
  {
    path: "/user/:id",
    name: "profile",
    meta: {
      title: "User Profile",
      auth: true,
      passive: false,
    },
    component: UserProfileView,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "error",
    meta: {
      title: Locale.get('page.not.found'),
      auth: false,
      passive: true,
    },
    component: ErrorView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

router.beforeEach((to) => {
  const title = to.meta.title as any;
  document.title = title?.toString();
});

export default router;
