import services from "../service";

export default {
    register(uuid : string) {
        return services.post('/election', {
            uuid: uuid,
        });
    },
    getBOD() {
        return services.get('/election/candidate/bod');
    },
    getElecom(type : string) {
        return services.get('/election/candidate/elecom/' + type);
    },
    getAudit() {
        return services.get('/election/candidate/audit');
    },
    getSchedule() {
        return services.get('/election/schedule');
    },
    verify(uuid : string, otp : string) {
        return services.post('/election/otp', {
            uuid: uuid,
            otp: otp,
        });
    },
    vote(uuid : string, votes : string) {
        return services.post('/election/vote', {
            uuid: uuid,
            votes: votes,
        });
    },
    resend(uuid : string) {
        return services.post('/election/otp/resend', {
            uuid: uuid,
        });
    },
    saveRemarks(payload : any) {
        return services.post('/election/remarks', payload);
    },
}
