import services from "../service";

export default {
    getSession(ssuid : string) {
        return services.get('/user/session', {
            ssuid: ssuid,
        });
    },
    login(username: string, password: string) {
        return services.post('/user/login', {
            username: username,
            password: password,            
        });
    },
    logout(ssuid: any) {
        return services.post('/user/logout', {
            ssuid: ssuid,
        });
    },
    signup(obj : object) {
        return services.post('/user/validate', obj);
    },
    signupResend(name : string, email : string, mobile : string) {
        return services.post('/user/register/resend', {
            name: name,
            email: email,
            mobile: mobile,
        });
    },
    createUser(obj : object) {
        return services.post('/user', obj);
    },
    forgotPassword(info : string) {
        return services.post('/user/forgot-password', {
            info: info,
        });
    },
    forgotPasswordResend(uuid : any) {
        return services.post('/user/forgot-password/resend', {
            uuid: uuid,
        });
    },
    updatePassword(pass1 : string, pass2 : string, uuid : string, score : number) {
        return services.post('/user/password', {
            new_password: pass1,
            con_password: pass2,
            uuid: uuid,
            score: score,
        });
    },
}
