import axios from "axios";
import cookie from "vue-cookies";

axios.defaults.params = {};
axios.defaults.params.token = "d7515cda9e82b5bebbe513d359b785b0";
axios.defaults.params.sessid = (cookie as any).get('sessid');

export default {

    /**
     * Current version of the api.
     * 
     * @var string
     */
    version: "v1",
    
    /**
     * Base URL of the api.
     * 
     * @var string
     */
    baseUrl: "https://staging.ms.pafcpic.ph/api/",
    
    /**
     * Generate an api endpoint.
     * 
     * @param path string
     * @returns string
     */
    endpoint(path : string) : string {
        if(path.substring(0, 1) == "/")
        {
            path = path.substring(1, path.length);
        }

        return this.baseUrl + this.version + "/" + path;
    },

    /**
     * Make a GET request to the API endpoint.
     * 
     * @param path string
     * @param params object
     * @returns any
     */
    get(path : string, params = {}) {
        return axios.get(this.endpoint(path), {
            params: params,
            withCredentials: true,
        });
    },

    /**
     * Make a POST request to the API endpoint.
     * 
     * @param path string
     * @param params object
     * @returns any
     */
    post(path : string, params = {}) {
        return axios.post(this.endpoint(path), params);
    },

    /**
     * Make a PUT request to the API endpoint.
     * 
     * @param path string
     * @param params object
     * @returns any
     */
    put(path : string, params = {}) {
        return axios.put(this.endpoint(path), {
            params: params,
            withCredentials: true,
        });
    },

    /**
     * Make a PATCH request to the API endpoint.
     * 
     * @param path string
     * @param params object
     * @returns any
     */
    patch(path : string, params = {}) {
        return axios.patch(this.endpoint(path), {
            params: params,
            withCredentials: true,
        });
    },

    /**
     * Make a DELETE request to the API endpoint.
     * 
     * @param path string
     * @param params object
     * @returns any
     */
    delete(path : string, params = {}) {
        return axios.delete(this.endpoint(path), {
            params: params,
            withCredentials: true,
        });
    },
}
