export default {
    'account.no': 'Account No.',
    'adcom': 'ADCOM',
    'already.have.an.account': 'Already have an account?',
    'and': 'and',
    'are.you.sure.you.want.to.proceed': 'Are you sure you want to proceed?',
    'are.you.sure.you.want.to.clear.all.your.votes.for.this.position': 'Are you sure you want to clear all your votes for this position?',
    'at.large.category': 'At-Large Category',
    'audit.and.supervisory.committee': 'Audit & Supervisory Committee',
    'board.of.directors': 'Board of Directors',
    'bod.at.large.category': 'BOD (At-Large Category)',
    'bod.civilian.category': 'BOD (Civilian Category)',
    'bod.enlisted.personnel.category': 'BOD (Enlisted Personnel Category)',
    'bod.officers.category': 'BOD (Officers Category)',
    'by.clicking.the.continue.button.you.are.agree.with.our': 'By clicking the "continue" button, you are agree with our',
    'cancel': 'Cancel',
    'next':'Next',
    'back':'Back',
    'civilian.category': 'Civilian Category',
    'clear': 'Clear',
    'close': 'Close',
    'confirm.eligibility': 'Confirm Eligibility',
    'confirm.password': 'Confirm Password',
    'continue': 'Continue',
    'create.an.account': 'Create an account',
    'dashboard': 'Dashboard',
    'days': 'Days',
    'elecom': 'ELECOM',
    'elecom.civilian.category': 'ELECOM (Civilian Category)',
    'elecom.enlisted.personnel.category': 'ELECOM (Enlisted Personnel Category)',
    'elecom.officers.category': 'ELECOM (Officers Category)',
    'election': 'Election',
    'election.committee': 'Election Committee',
    'election.policy': 'Policy in the Conduct of Annual General Assembly',
    'election.Code': 'Election Code',
    'election.start': 'Election Start',
    'email': 'Email',
    'enlisted.personel.category': 'Enlisted Personnel Category',
    'error': 'Error',
    'fill.out.the.fields.below.to.recover.your.account': 'Fill out the fields below to recover your account.',
    'first.name': 'First Name',
    'forgot.password': 'Forgot password?',
    'get.started': 'Get Started',
    'go.to.dashboard': 'Go to Dashboard',
    'help.center': 'Help Center',
    'hours': 'Hours',
    'i.agree.to.this.policies': 'I agree to this policies',
    'i.understand.the.code': 'I Understand the Code',
    'invalid.username.or.password': 'Invalid username or password.',
    'it.seems.that.you.are.experiencing.some.network.issues': 'It seems that you are experiencing some network issues.',
    'join.election': 'Join Election',
    'last.name': 'Last Name',
    'loans': 'Loans',
    'log.in': 'Log In',
    'login.failed': 'LogIn Failed',
    'log.in.now': 'Log In Now!',
    'log.out': 'Log Out',
    'middle.name': 'Middle Name',
    'min': 'Min',
    'mobile.no': 'Mobile No.',
    'my.papsi': 'MyPAPSI',
    'my.papsi.and.other.related.brands.are.owned.and.operated.by.the.philippine.army.finance.center.producers.integrated.cooperative.use.of.any.of.this.trademarks.without.written.conscent.from.the.owner.is.prohibited': 'My Papsi and other related brands are owned by the Philippine Army Finance Center Producers Integrated Cooperative. Use of any of this trademarks without written conscent from the owner is prohibited.',
    'my.votes': 'My Votes',
    'network.unavailable': 'Network Unavailable',
    'new.password': 'New Password',
    'nice.to.see.you.again': 'Nice to see you again!',
    'officer.category': 'Officer Category',
    'of': 'of',
    'ok': 'OK',
    'oops': 'Oops!',
    'our.company': 'Our Company',
    'page.not.found': 'Page Not Found',
    'password': 'Password',
    'password.recovery': 'Password Recovery',
    'please.cast.the.required.number.of.vote.for.this.position.to.proceed': 'Please cast the required number of votes for this position to proceed.',
    'please.check.your.votes.for.each.category.you.can.always.go.back.to.update.your.votes': 'Please check your votes for each category. You can always go back to update your votes.',
    'please.draw.your.signature.below.before.you.submit.your.votes': 'Please draw your signature below before you submit your votes.',
    'please.enter.a.new.password': 'Please enter a new password.',
    'please.enter.the.otp.we.have.sent.to.your.email.or.mobile.number': 'Please enter the OTP we have sent to your email or mobile number.',
    'please.fill.all.the.missing.fields': 'Please fill all the missing fields.',
    'please.fill.all.the.required.fields.below': 'Please fill all the required fields below.',
    'pre.register.now': 'Pre-Register Now!',
    'privacy.policy': 'Privacy Policy',
    'proceed': 'Proceed?',
    'username': 'Username',
    'update.password': 'Update Password',
    'register': 'Register',
    'regular.savings': 'Regular Savings',
    'resend': 'Resend',
    'sec': 'Sec',
    'serial.no': 'Serial No.',
    'settings': 'Settings',
    'share.capital': 'Share Capital',
    'signature': 'Signature',
    'something.went.wrong': 'Something went wrong.',
    'special.savings': 'Special Savings',
    'start.voting': 'Start Voting',
    'submit': 'Submit',
    'skip': 'Skip',
    'success': 'Success!',
    'suffix': 'Suffix',
    'terms.of.use': 'END USER LICENSE AGREEMENT',
    'that.governs.your.overall.use.of.this.application': 'that governs your overall use of this application.',
    'try.again': 'Try Again',
    'user.settings': 'User Settings',
    'validate.otp': 'Validate OTP',
    'version': 'version',
    'vote.summary': 'Vote Summary',
    'welcome': 'Welcome',
    'welcome.ka.may.ari': 'Welcome Ka-may-ari',
    'will.start.in': 'will start in',
    'you.have.reached.the.maximum.number.of.votes.for.this.position': 'You have reached the maximum number of votes for this position.',
    'your.account.has.been.successfully.created': 'Your account has been successfully created.',
    'your.password.has.been.successfully.changed': 'Your password has been successfully changed.',
    'your.vote.has.been.submitted': 'Your vote has been submitted.',
    'ratings.view' : 'Ratings View',
}