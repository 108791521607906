import { createStore } from "vuex";

export default createStore({
  state: {
    sessid: null,
    timestamp: null,
    forgot_password: {
      otp: null,
      username: null,
      email: null,
      mobile: null,
      uuid: null,
    },
    registration: {
      submitted: false,
      otp: '',
      lname: '',
      fname: '',
      mname: '',
      suffix: '',
      account: '',
      serial: '',
      username: '',
      email: '',
      mobile: '',
      pass1: '',
      pass2: '',
    },
    user: {
      uuid: null,
      ssuid: null,
      status: false,
      username: null,
      ga_register: false,
      registered: false,
      started: false,
      voted: false,
      email: null,
      mobile: null,
      lang: null,
      first_name: null,
      last_name: null,
      middle_name: null,
      account_no: null,
      serial: null,
    },
    election: {
      migs: false,
      otp: null,
      ballot: null,
      vote_start: null,
      vote_end: null,
      vote_register: null,
    },
    election_loaded: false,
    election_done: false,
    candidate_list: {
      bod: [],
      elecom_officers: [],
      elecom_ep: [],
      elecom_cp: [],
      adcom: [],
    },
    candidates: [],
    bod: {
      of: [],
      ep: [],
      ci: [],
      al: [],
    },
    votes: {
      bod: [],
      elecom_officers: [],
      elecom_ep: [],
      elecom_cp: [],
      adcom: [],
    },
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
